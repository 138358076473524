import React from "react";
import {Link} from "react-router-dom";

const SliderCard = ({ data }) => {
  const { bgImg } = data;

//  const { img, details, tag } = data;
  let json_data = JSON.stringify(data)
//  let state_data = useState(json_data)

  return (
    <div className="slider" style={{ backgroundImage: `url(${bgImg})` }}>
      <div className="slide_content">
        <div className="slide_content_wrapper mb-0 h-auto bg-dark-100">
          <div className="slide_content_inner">
            <div className="meta m-0">
              <div className="category text-olive text-uppercase">
                {data.highlight}
              </div>
            </div>
            <h4>
              <a  className="text-white">
                {data.name}
              </a>
            </h4>
            <div className="details_link">
              <Link to="/project-details" state={json_data}>
          <span className="link_text"><span className="link_text">View Details</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></span>
                <i className="fa-solid fa-arrow-right-long"></i>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SliderCard;
