
import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { getProjectData } from './project_data';

const ProjectDataContext = createContext();

export const ProjectDataProvider = ({ children }) => {
    const [projectData, setProjectData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            console.log('ProjectDataProvider: Fetching data...');
            try {
                const data = await getProjectData();
                console.log('ProjectDataProvider: Data fetched successfully', data);
                setProjectData(data);
                setIsLoading(false);
            } catch (err) {
                console.error('ProjectDataProvider: Error fetching data', err);
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // Use useMemo to memoize the context value
    const value = useMemo(() => ({
        projectData,
        isLoading,
        error
    }), [projectData, isLoading, error]);

    console.log('ProjectDataProvider rendering with:', value);

    useEffect(() => {
        if (projectData) {
            sessionStorage.setItem('projectData', JSON.stringify(projectData));
        }
    }, [projectData]);

    return (
        <ProjectDataContext.Provider value={value}>
            {children}
        </ProjectDataContext.Provider>
    );
};

export const useProjectData = () => {
    const context = useContext(ProjectDataContext);
    if (context === undefined) {
        throw new Error('useProjectData must be used within a ProjectDataProvider');
    }
    return context;
};