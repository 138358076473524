import React, {useEffect, useState} from "react";
import FunFacts from "../../containers/FunFacts/FunFacts";
import {Sliders} from "../../containers/SliderContainers/Sliders";
import Isotope from "isotope-layout";
import SectionHeader from "../../components/SectionHeader";
import Loader from "../Shared/Loader";




const Home01 = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    useEffect(() => {
        Isotope.current = new Isotope('.grid', {
            itemSelector: '.grid-item',
            percentPosition: true,
             layoutMode: 'packery',
             masonry: {
                 columnWidth: '.grid-sizer'
             },
            transformsEnabled: true,
            transitionDuration: "700ms",
            resize: true,
            fitWidth: true,
            columnWidth: '.grid-sizer',
            options: {
                layoutMode: 'masonry',
                masonry: { columnWidth: 100 },
                transitionDuration: '0.5s'
            }
        })

        // cleanup
        //return () => Isotope.current.destroy()
    });

    //useEffect(() => {
        //const fetchData = async () => {
          //  try {
          //      setIsLoading(true);
          //      const data = await getProjectData();
          //      console.log(JSON.stringify(data['body']));
                // Store the data in session storage
          //      sessionStorage.setItem('projectData', JSON.stringify(data));
          //      setIsLoading(false);
          //  } catch (error) {
          //      console.error('Error fetching project data:', error);
          //      setError(error.message);
          //      setIsLoading(false);
          //  }
        //};

        //fetchData();
    //}, []);

 //   if (isLoading) return <div>Loading...</div>;
 //   if (error) return <div>Error: {error}</div>;

// <FunFacts className="funfacts bg_1" />
  return (



    <div className="wrapper">
        <Loader></Loader>
        <div className="large_font">
        <SectionHeader title="Evolution Design"></SectionHeader>
        </div>
        <Sliders></Sliders>
        <FunFacts className="funfacts bg_1" />







    </div>
  );
};

export default Home01;
