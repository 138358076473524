import Isotope from 'isotope-layout';
import React, { useEffect, useState } from 'react';
import PageHeder from '../../components/PageHeder';
import Paragraph from '../../components/Paragraph';
import ProjectCard from '../../components/ProjectElements/ProjectCard';
import SectionGridLines from '../../components/SectionGridLines';
import SectionHeader from '../../components/SectionHeader';
import { useProjectData } from '../../data/ProjectDataContext';
const ProjectGallery = () => {
    console.log("In Project Gallery")
    const {projectData, isLoading, error} = useProjectData();
    const [filterKey, setFilterKey] = useState('*');
    const [isotope, setIsotope] = useState(null);

    useEffect(() => {
        if (projectData && !isLoading) {
            const iso = new Isotope('.grid', {
                itemSelector: '.grid-item',
                percentPosition: true,
              //  layoutMode: 'packery',
              //  masonry: {
              //      columnWidth: '.grid-sizer'
              //  },
                transformsEnabled: true,
                transitionDuration: "700ms",
                resize: true,
                fitWidth: true,
            });
            setIsotope(iso);
        }
    }, [projectData, isLoading]);

    useEffect(() => {
        if (isotope) {
            filterKey === '*'
                ? isotope.arrange({filter: `*`})
                : isotope.arrange({filter: `.${filterKey}`});
        }
    }, [isotope, filterKey]);
    const handleFilterKeyChange = (key) => () => {
        setFilterKey(key);
    };
    console.log("ProjectGallery data:", {projectData, isLoading, error});
    console.log(projectData)

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!projectData) {
        return <div>No project data available</div>;
    }

    return (
        <section className="projects packery">
            <SectionGridLines></SectionGridLines>
            <div className="large_font">
                <SectionHeader title="Gallery"></SectionHeader>
            </div>
            <div className="container">
                <div className="section-header text-center has_line">
                    <PageHeder className='text-white' title="Project Gallery"></PageHeder>
                    <div className="section-desc row align-items-center justify-content-center">
                        <div className="col-lg-6 text-end">
                            <Paragraph text=""></Paragraph>
                        </div>
                        <div className="col-lg-6 text-start">
                            <Paragraph text=""></Paragraph>
                        </div>
                    </div>
                </div>

                <div className="portfolio-filters-content">
                    <div className="filters-button-group">
                        <button className="button" onClick={handleFilterKeyChange('*')}>All <sup
                            className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('workplace-design')}>Workplace
                            Design <sup className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('industrial')}>Industrial <sup
                            className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('restaurant')}>Restaurant <sup
                            className="filter-count"></sup></button>
                        <button className="button" onClick={handleFilterKeyChange('retail')}>Retail <sup
                            className="filter-count"></sup></button>
                    </div>
                </div>

                <div className="grid gutter-20 clearfix">
                    <div className="grid-sizer"></div>

                    {

                        projectData.map(data => <ProjectCard
                            key={data.key}
                            data={data}
                        />)
                    }
                </div>
                {/* <LoadmoreBtn text='Discover All Projects' className='btn olive w-100' /> */}
            </div>
        </section>
    );
}


export default ProjectGallery;