













export const FunFactData = [

    {
        id: '2',
        contentName: 'Project Completed',
        contentNumber: '3200',
        plus: '+',
        img: 'images/fact-2.png'
    },
    {
        id: '3',
        contentName: 'Industry Awards',
        contentNumber: '1021',
        img: 'images/fact-3.png'
    },
    {
        id: '4',
        contentName: 'Project Running',
        contentNumber: '12',
        img: 'images/fact-4.png'
    }
];