import React from 'react';
import {Link} from 'react-router-dom';
const TeamCard = ({ data }) => {
    const { img, name, designation,blub } = data;
    let json_data = JSON.stringify(data)

    return (
        <div className="col-lg-4 col-md-4">
            <div className="team-block">
                <img src={img} alt="img" />
                <h5><Link to="/team-details" state={json_data}>
                    <span className="white-text"><span className="link-text">{name}</span></span></Link></h5>
                <h6 className="text-uppercase text-olive">{designation}</h6>
            </div>
        </div>
    );
};

export default TeamCard;


