import React from 'react';
import { EffectFade, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import ProjectDetailsInner from '../../components/ProjectElements/ProjectDetailsInner';
import ProjectDetailsSlider from '../../components/ProjectElements/ProjectDetailsSlider';
import PrjDetailLeftSide from '../../components/PrjDetailLeftSide';

const ProjectDetailsContainer = (props) => {
    const { detail_images } = props.data_state;
   // console.log(detail_images)

    return (
        <section className="project-details bg-dark-200">
            <div className="container">
                <div className="gallery_slider">
                    <div className="swiper swiper_gallery">
                        <Swiper
                            modules={[EffectFade, Navigation]}
                            effect="slide"
                            slidesPerView={1}
                            loop={true}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            {detail_images.map((image, id) => (
                                <SwiperSlide key={id}>
                                    <ProjectDetailsSlider img={image} />
                                </SwiperSlide>
                            ))}
                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-4">
                        <PrjDetailLeftSide data_state={props.data_state} />
                    </div>

                    <div className="col-lg-8 col-md-8">
                        <ProjectDetailsInner data_state={props.data_state}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ProjectDetailsContainer;