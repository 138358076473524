
import axios from 'axios';

// Function to fetch General data from API Gateway
async function fetchGeneralData() {
        try {
                console.log("Fetching data from API...");
                const response = await axios.get('https://zpenyvg1n1.execute-api.us-west-2.amazonaws.com/prd/general/');
                console.log("Full API General Data Response:", response.data);
                return response.data;
        } catch (error) {
                console.error('Error fetching general data:', error);
                return null;
        }
}

// Instead of exporting teamData directly, export a function that returns a promise
export async function getGeneralData() {
        let cachedData = null;

        if (cachedData) {
                return cachedData;
        } else {
                const response = await fetchGeneralData();
                console.log("Full response received:", response);

                if (!response || !response.body) {
                        console.error('Failed to fetch General data or body is undefined');
                        return [];
                }

                let rawData;
                try {
                        rawData = JSON.parse(response.body);
                } catch (error) {
                        console.error('Error parsing JSON from body:', error);
                        return [];
                }

                console.log("Raw data extracted:", rawData);

                if (!Array.isArray(rawData)) {
                        console.error('Unexpected data structure:', rawData);
                        return [];
                }
                // Transform the data to match TeamData structure
                cachedData = rawData.map(item => ({
                        id: item.id,
                        description_text_small: item.description_text_small,
                        mission_statement_text: item.mission_statement_text,
                        location_address_text: item.location_address_text,
                        location_suite: item.location_suite,
                        location_city_state_zip_text: item.location_city_state_zip_text,
                        location_phone_text: item.location_phone_text,
                        location_email_text: item.location_email_text,
                        location_fax_text: item.location_fax_text,
                        website_url_text: item.website_url_text,
                        about_header: item.about_header,
                        about_mission: item.about_mission,
                        about_us_1_header: item.about_us_1_header,
                        about_us_1_text: item.about_us_1_text,
                        about_us_2_header: item.about_us_2_header,
                        about_us_2_text: item.about_us_2_text,
                        about_us_3_header: item.about_us_3_header,
                        about_us_3_text: item.about_us_3_text,
                        about_us_4_header: item.about_us_4_header,
                        about_us_4_text: item.about_us_4_text,
                        contact_message: item.contact_message,
                        instagram_url: item.instagram_url,
                        facebook_url: item.facebook_url,
                        x_url: item.x_url,
                        T_N_C_P: item.T_N_C_P,
                        T_N_C_URL: item.T_N_C_URL,
                        evd_copyright: item.evd_copyright
                }));
                console.log(cachedData)

                return cachedData;
        }
}




export const formatDate = (date) => {
    return new Date(date).toLocaleDateString();
};

export const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
};


