import React, { useLayoutEffect, useRef, useState } from "react";
import LazyLoad from 'react-lazyload';
import {Link} from 'react-router-dom';
const ProjectCard = ({ data }) => {
    const targetRef = useRef();
    const [setDimensions] = useState({ width: 0, height: 0 });
    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    const monitorWidth = windowSize.current[0];
    //const navigate = useNavigate();
    // console.log(monitorWidth);


    useLayoutEffect(() => {
        if (targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, [setDimensions]);

    //let elHeight = Number(dimensions.height);

    const { img, details, tag } = data;
    let json_data = JSON.stringify(data)
    console.log('ProjectCard',json_data);
    //const state_data = useState(json_data)
    //const handleClick = () => {
    //    navigate('/project-details',{state:json_data})
    //};

    return (
        <div className={`grid-item ${tag} width-50`}>
            <div className="thumb">
                <LazyLoad height={monitorWidth <= 600 ? 500 : monitorWidth <= 1550 ? 680 : monitorWidth <= 2000 ? 810 : 680} once>
                    <img className="item_image" src={img} alt="" />
                </LazyLoad>
                <div className="works-info">
                    <div className="label-text">


                        <h5>{details}</h5>
                        <div className="details_link">
                            <Link to="/project-details" state={json_data}>
                                <span className="link_text"><span className="link_text">View Details</span> <span className="link_icon"><span className="line"></span> <span className="circle"></span><span className="dot"></span></span></span>
                                <i className="fa-solid fa-arrow-right-long"></i>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectCard;