import $ from 'jquery';
import React, {useEffect} from 'react';
import SectionGridLines from '../../../components/SectionGridLines';
import { useGeneralData } from "../../../data/GeneralDataContext"

const Footer = () => {
    const { evd_general_data, isLoading, error } = useGeneralData();

    useEffect(() => {
        // Totop Button
        $('.totop a').on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({ scrollTop: 0 }, '300');
        });

        // Hide header on scroll down
        const nav = document.querySelector(".header");
        const scrollUp = "top-up";
        let lastScroll = 800;

        window.addEventListener("scroll", () => {
            const currentScroll = window.pageYOffset;
            if (currentScroll <= 800) {
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
                return;
            }

            if (currentScroll > lastScroll) {
                // down
                nav.classList.add(scrollUp);
                $('.totop').addClass('show');

            } else if (currentScroll < lastScroll) {
                // up
                nav.classList.remove(scrollUp);
                $('.totop').removeClass('show');
            }
            lastScroll = currentScroll;
        });

    }, []);


    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!evd_general_data || evd_general_data.length === 0) return <div>No General data available</div>;
    //console.log(projectData)
    return (
        <>
            <footer className="footer bg-dark-200 box_padding">
                <div className="footer_inner bg-black">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-lg-1 col-sm-10 col-sm-2">
                                <div className="section-header">
                                    <h2>Contact</h2>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-2 col-sm-5">
                                <div className="communication">
                                    <div className="info_body"
                                    >
                                        <h6>EVD Website</h6>
                                        <h5>{evd_general_data[0].website_url_text}</h5>
                                    </div>
                                    <div className="info_body"
                                    >
                                        <h6>Email Address</h6>
                                        <h5>{evd_general_data[0].location_email_text}</h5>
                                    </div>
                                    <div className="info_body">
                                        <h6>Phone No</h6>
                                        <h5>{evd_general_data[0].location_phone_text}</h5>
                                    </div>
                                    <div className="info_body">
                                        <h6>FAX No</h6>
                                        <h5>{evd_general_data[0].location_fax_text}</h5>
                                    </div>
                                    <div className="info_body">
                                        <h6>Office Address</h6>
                                        <h5>{evd_general_data[0].location_address_text}</h5>
                                        <h5>{evd_general_data[0].location_suite}</h5>
                                        <h5>{evd_general_data[0].location_city_state_zip_text}</h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-5">
                                <div className="footer_elements d-flex align-items-center justify-content-end">
                                    <div className="footer_elements_inner">
                                        <div className="footer_logo"
                                        >
                                            <a href="/" className="light_logo"><img src="images/EvD_logo.png" alt="logo" /></a>
                                        </div>
                                        <div className="footer_social">
                                            <ul className="social_list">
                                                <li className="facebook"
                                                ><a href={evd_general_data[0].facebook_url} target="_blank" rel="noopener noreferrer"><i className="bi bi-facebook"></i></a></li>
                                                <li className="twitter"
                                                ><a href={evd_general_data[0].x_url} target="_blank" rel="noopener noreferrer"><i className="bi bi-twitter"></i></a></li>
                                                <li className="instagram"
                                                ><a href={evd_general_data[0].instagram_url} target="_blank" rel="noopener noreferrer"><i className="bi bi-instagram"></i></a></li>
                                            </ul>
                                        </div>
                                        <div className="terms_condition">
                                            <ul>
                                                <li><a href={evd_general_data[0].T_N_C_URL} target="_blank" rel="noopener noreferrer">{evd_general_data[0].T_N_C_P}</a></li>
                                            </ul>
                                        </div>
                                        <div className="copyright"
                                        >
                                            <p>{evd_general_data[0].evd_copyright}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <SectionGridLines />
                </div>
            </footer >

            <div className="totop">
                <a href="#">UP</a>
            </div>
        </>
    );
};

export default Footer;