import React from 'react';

const TextAbout = () => {
    return (
        <section className="highlight_banner bg-dark-200">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-11 p-lg-0">
                        <p className="about_para text-center"><span>We want to develop </span>lasting <span>business relationships. We strive to</span> exceed our client’s goals and objectives<span> not only in design solutions </span> but more importantly in client service.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TextAbout;