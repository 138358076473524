import React from 'react';
import { EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/react";
import TeamCard from '../../components/TeamElements/TeamCard';
import { useTeamData } from '../../data/TeamDataContext';





const TeamContainer = () => {

    const { teamData, isLoading, error } = useTeamData();

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!teamData || teamData.length === 0) return <div>No team data available</div>;
    return (
        <section >
            <div className="container">
                <div className="section-header text-center has_line">

                </div>

                <div className="team_inner">
                    <div className="swiper swiper_team">
                        <Swiper
                            modules={[Navigation, EffectFade, Pagination]}
                            effect="slide"
                            breakpoints={{
                                600: {
                                    width: 600,
                                    slidesPerView: 2,
                                    spaceBetween: 30,
                                },
                                768: {
                                    width: 768,
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                },
                                992: {
                                    width: 992,
                                    slidesPerView: 3,
                                    spaceBetween: 65,
                                },
                            }}
                            autoHeight={true}
                            parallax={false}
                            controller={{
                                inverse: true,
                            }}
                            slideToClickedSlide={true}
                            loop={false}
                            navigation={{
                                prevEl: '.swiper-button-prev',
                                nextEl: '.swiper-button-next',
                            }}
                        >
                            {
                                teamData.map(data =>
                                    <SwiperSlide>
                                        <TeamCard
                                            key={data.id}
                                            data={data}
                                        />
                                    </SwiperSlide>)
                            }
                        </Swiper>

                        {/* <!-- Add Buttons --> */}
                        <div className="swiper-navigation">
                            <div className="swiper-button-prev"><i className="bi bi-arrow-left"></i></div>
                            <div className="swiper-button-next"><i className="bi bi-arrow-right"></i></div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
};

export default TeamContainer;