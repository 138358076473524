import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { getGeneralData } from './general_data';

const GeneralDataContext = createContext();

export const GeneralDataProvider = ({ children }) => {
    const [evd_general_data, setGeneralData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            console.log('GeneralDataProvider: Fetching data...');
            try {
                const data = await getGeneralData();
                console.log('GeneralDataProvider: Data fetched successfully', data);
                setGeneralData(data);
                setIsLoading(false);
            } catch (err) {
                console.error('GeneralDataProvider: Error fetching data', err);
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // Use useMemo to memoize the context value
    const value = useMemo(() => ({
        evd_general_data,
        isLoading,
        error
    }), [evd_general_data, isLoading, error]);

    console.log('GeneralDataProvider rendering with:', value);

    useEffect(() => {
        if (evd_general_data) {
            sessionStorage.setItem('evd_general_data', JSON.stringify(evd_general_data));
        }
    }, [evd_general_data]);

    return (
        <GeneralDataContext.Provider value={value}>
            {children}
        </GeneralDataContext.Provider>
    );
};

export const useGeneralData = () => {
    const context = useContext(GeneralDataContext);
    if (context === undefined) {
        throw new Error('useGeneralData must be used within a GeneralDataProvider');
    }
    return context;
};