import $ from "jquery";
import React, { useEffect, useState } from "react";
import EvD_logo from "../../../assets/EvD_logo.png";

const UpperHeader = () => {
  // Hide header on scroll down
  window.onscroll = function () {
    myFunction();
  };
  function myFunction() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.querySelector("header .container").classList.add(".top-up");
    } else {
      document.querySelector("header .container").classList.remove(".top-up");
    }
  }

  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    // Aside info bar
    $(".aside_open").click(function (e) {
      e.preventDefault();
      $(".aside_info_wrapper").addClass("show");
    });
    $(".aside_close").click(function (e) {
      e.preventDefault();
      $(".aside_info_wrapper").removeClass("show");
    });

    // Toggle Header Search
  //  $(".header_search .form-control-submit").click(function (e) {
  //    $(".open_search").toggleClass("active");
  //  });

    // Sticky Header
    var header = $("header");
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();

      if (scroll >= 50) {
        header.addClass("bg-dark sticky");
      } else {
        header.removeClass("bg-dark sticky");
      }
    });
  }, []);

  return (
    <header className="header">
      <div className="container">
        <div className="header_inner d-flex align-items-center justify-content-between">
          <div className="logo">
            <a href="/" className="light_logo">
              <img src={EvD_logo} alt="logo" />
            </a>
            <a href="/" className="dark_logo">
              <img src={EvD_logo} alt="logo" />
            </a>
          </div>

          <div className="mainnav d-none d-lg-block">
            <ul className="main_menu">
              <li className="menu-item">
                <a href="/">Home</a>
              </li>
              <li className="menu-item">
                <a href="/projects">Gallery</a>
              </li>
              <li className="menu-item">
                <a href="/team">Team</a>
              </li>
              <li className="menu-item">
                <a href="/about">About</a>


              </li>
              <li className="menu-item">
                <a href="/contact">Contact</a>
              </li>

            </ul>
          </div>
          <div className="header_right_part d-flex align-items-center">

            {/* Mobile Responsive Menu Toggle Button */}
            <button type="button" className="mr_menu_toggle d-lg-none">
              <i className="bi bi-list"></i>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
};

export default UpperHeader;
