import React from 'react';

const ProjectDetailsSlider = (props) => {
    console.log("ProjectDetailsSlider Props :" + props.img.image)
    return (
        <div className="swiper-slide">
            <div className="gallery-image">
                <img src={props.img.image} alt="img" />
            </div>
        </div>
    );
};

export default ProjectDetailsSlider;