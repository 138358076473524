import axios from 'axios';

// Function to fetch Team data from API Gateway
async function fetchProjectData() {
    try {
        console.log("Fetching data from API...");
        const response = await axios.get('https://zpenyvg1n1.execute-api.us-west-2.amazonaws.com/prd/team/');
        console.log("Full API Response:", response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching team data:', error);
        return null;
    }
}

// Instead of exporting teamData directly, export a function that returns a promise
export async function getTeamData() {
    let cachedData = null;

    if (cachedData) {
        return cachedData;
    } else {
        const response = await fetchProjectData();
        console.log("Full response received:", response);

        if (!response || !response.body) {
            console.error('Failed to fetch team data or body is undefined');
            return [];
        }

        let rawData;
        try {
            rawData = JSON.parse(response.body);
        } catch (error) {
            console.error('Error parsing JSON from body:', error);
            return [];
        }

        console.log("Raw data extracted:", rawData);

        if (!Array.isArray(rawData)) {
            console.error('Unexpected data structure:', rawData);
            return [];
        }
        // Transform the data to match TeamData structure
        cachedData = rawData.map(item => ({
            id: item.id,
            img: item.img,
            name: item.name,
            designation:item.designation,
            blurb: item.blurb,
        }));
        console.log(cachedData)

        return cachedData;
    }
}














export const TeamData666 = [
    {
        id: 'RT01',
        img: 'images/team/rthomas.jpg',
        name: 'Roberta Thomas',
        designation: 'PRINCIPAL',
        blurb:"Roberta Thomas is the Principal and Founder of evolutiondesign and a highly accomplished interior designer with over 41 years of experience. As a NCIDQ certified professional and member of ASID and IIDA, Roberta has led numerous award-winning interior design projects for corporate offices, banking/financial firms, legal offices, call centers, facilities/institutions, hospitality, retail, public spaces, and medical facilities. Her portfolio showcases exceptional designs for notable clients such as Republic Services, Perkins Coie, Greenberg Traurig, America West Airlines, Salt River Project, and the Arizona State Senate. Roberta's work has been consistently recognized for excellence, earning over 75 ASID and IIDA design awards, including being named to Ranking Arizona's Top Interior Design Firms list multiple times. With a Bachelor of Science in Design from Arizona State University, Roberta brings passionate creativity and seasoned expertise to every project."
    },
    {
        id: 'JL01',
        img: 'images/team/jlear.jpg',
        name: 'Jeremy Lear',
        designation: 'PRINCIPAL',
        blurb:"Jeremy Lear is a Principal and Technical Coordinator with extensive experience in space planning, construction documentation, engineering coordination, and construction administration. As a LEED Accredited Professional, he brings expertise in sustainable design practices to every project. Jeremy has overseen a diverse portfolio spanning corporate offices, restaurants, industrial facilities, public spaces, medical/spa centers, legal/financial firms, and educational institutions. His representative projects include the 490,000 sq ft America West Airlines Consolidation, the 360,000 sq ft Westgate Entertainment District, and facilities for major clients like Santander, DaVita, Republic Services, GE Finance, and Grand Canyon University. With a Bachelor of Science in Interior Design from Ohio University and minors in Art and Business, Jeremy combines technical proficiency with creative vision to deliver exceptional results for his clients."
    },
    {
        id: 'AG01',
        img: 'images/team/agivens.jpg',
        name: 'Angela Givens',
        designation: 'PRINCIPAL',
        blurb:"Angela Givens is a key member of the leadership team at Evolution Design. As a principal of the firm, she oversees the day-to-day business operations, ensuring seamless coordination and execution of projects across various sectors, including corporate office, banking, legal, industrial, back-office facilities, medical, hospitality, retail, and restaurant design. With a strong background in business operations and a deep understanding of the industry, Angela plays a crucial role in maintaining the firm's high standards of excellence and delivering exceptional client experiences. Her expertise and commitment to operational efficiency contribute significantly to evolutiondesign's success in providing top-notch interior architecture solutions."

    },
    {
        id: 'ST01',
        img: 'images/team/sthomas.jpg',
        name: 'Scott Thomas',
        designation: 'PRINCIPAL',
        blurb:"Scott Thomas is a Principal with a background in industrial engineering, facility planning, and operations, Scott plays a vital role in the firm's business development efforts. His expertise in these areas enables him to identify and pursue new opportunities effectively, ensuring the continued growth and success of evolutiondesign. Scott's deep understanding of industrial processes, space planning, and operational requirements allows him to provide valuable insights and solutions tailored to the unique needs of clients across various sectors, including corporate office, banking, legal, industrial, back-office facilities, medical, hospitality, retail, and restaurant design. His contributions to the firm's business development strategies have been instrumental in expanding its reach and solidifying its position as a leader in the interior architecture industry."
    },
    {
        id: 'JH01',
        img: 'images/team/hammen.jpg',
        name: 'Helmut "John" Hammen',
        designation: 'PRINCIPAL',
        blurb:"Helmut John Hammen is a Principal and Project Architect with over 33 years of extensive experience spanning all phases of design and construction. He brings deep technical expertise across a broad range of building types including healthcare, industrial, educational, institutional, commercial, and entertainment facilities. John's strong understanding of building materials, systems, and construction needs allows him to expertly guide projects from conception through successful execution. He is an excellent facilitator who fosters seamless collaboration between design teams, construction crews, and clients. John's portfolio encompasses impactful projects for major brands like Santander, Volkswagen, Volvo, DaimlerChrysler, Maricopa County, Burroughs Corporation, and Little Caesars Enterprises. He holds a Bachelor of Architecture from the University of Detroit and is a registered architect in Arizona as well as being NCARB certified."
    },

]