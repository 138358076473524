import React from 'react';
import Form from './Form';
import Paragraph from './Paragraph';
import SectionGridLines from './SectionGridLines';
import {useGeneralData} from "../data/GeneralDataContext";

const ContactForm = () => {

    const { evd_general_data, isLoading, error } = useGeneralData();
    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!evd_general_data || evd_general_data.length === 0) return <div>No General data available</div>;
    console.log("HERE HERE HERE HERE HERE HERE HERE HERE HERE HERE HERE HERE HERE HERE HERE HERE HERE")
    console.log(evd_general_data[0].contact_message)
    return (
        <section className="contact_us bg-dark-200">
            <div className="container">
                <div className="row justify-content-between">
                    <div className="col-lg-5">
                        <div className="section-header">
                            <h1 className="text-white text-uppercase mb-4">{evd_general_data[0].contact_message}</h1>
                            <Paragraph text={evd_general_data[0].about_us_4_text} />
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <Form />
                    </div>
                </div>
            </div>

            <SectionGridLines />
        </section>
    );
};

export default ContactForm;