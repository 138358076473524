import React from 'react';
import PageBanner from '../../containers/PageBanner';
import ProjectDetailsContainer from '../../containers/ProjectContainers/ProjectDetailsContainer';
import { useLocation } from 'react-router-dom';
import FunFacts from "../../containers/FunFacts/FunFacts";



const reviverFunction = (key, value) => {
    if (key === null)
        return undefined;
    if (value === null) {
        // Handle or filter out null values here
        return undefined; // Return undefined to remove null values from the parsed object
    }
    return value;
};
const ProjectDetails = () => {


        let location = useLocation();
        let data = JSON.parse(location.state, reviverFunction);
        console.log("data in Project Detail", data);

            return (
                <main className="wrapper">
                    <PageBanner pageTitle='Projects' title={data.details}/>
                    <ProjectDetailsContainer data_state={data}/>

                </main>
            );


    }
    ;


    export default ProjectDetails;
