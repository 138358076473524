import React from 'react';

const PrjDetailLeftSide = (props) => {
    return (
        <div className="sidebar">
            <div className="project-information">
                <h6 className="widget-title">
                    Project Information
                    <span className="title-line"></span>
                </h6>
                <ul>
                    <li>
                        <span className="data">Project Name:</span> <span className="value">{props.data_state.details}</span>
                    </li>
                    <li>
                        <span className="data">Project Lead:</span> <span className="value">{props.data_state.project_lead}</span>
                    </li>
                    <li>
                        <span className="data">Concept:</span> <span className="value">{props.data_state.type}</span>
                    </li>
                    <li>
                        <span className="data">Year</span> <span className="value">{props.data_state.completion_year}</span>
                    </li>
                    <li>
                        <span className="data">Location:</span> <span className="value">{props.data_state.location}</span>
                    </li>

                </ul>

                <div className="project-share">
                    <ul>
                        <li>Share:</li>
                        <li>
                            <a href="https://www.facebook.com/EvolutionDesignIncorporated/?ref=hl" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-facebook"></i>
                            </a>
                        </li>
                        <li><i className="bi bi-twitter"></i></li>
                        <li><a href="#"><i className="bi bi-instagram"></i></a></li>
                        <li>
                            <a href="https://www.linkedin.com/in/evolution-design-7986b628" target="_blank" rel="noopener noreferrer">
                                <i className="bi bi-youtube"></i>
                            </a>
                        </li>
                        <li><i className="bi bi-pinterest"></i></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default PrjDetailLeftSide;