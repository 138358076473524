import React from 'react';
const ProjectDetailsInner = (props) => {
console.log("ProjectDetailsInner Props :" + props.data_state.tag)

    return (
        <div className="project-details_inner">
            <div className="post_content">
                <div className="post-header">
                    <h3 className="post-title">{props.data_state.details}</h3>
                </div>
                <div className="fulltext">
                    <p>
                        {props.data_state.client_bio}
                    </p>

                    <h6 className="widget-title">
                        Project Features
                        <span className="title-line"></span>
                    </h6>
                    <p>{props.data_state.project_features_1}</p>
                    <ul className="point_order">
                        <li>{props.data_state.project_features_bullet_1}</li>
                        <li>{props.data_state.project_features_bullet_2}</li>
                        <li>{props.data_state.project_features_bullet_3}</li>
                    </ul>

                    <p>
                        {props.data_state.project_features_2}
                    </p>

                    <p>
                        {props.data_state.project_features_3}
                    </p>


                </div>

            </div>
        </div>
    );
};

export default ProjectDetailsInner;