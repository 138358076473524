import { createBrowserRouter } from "react-router-dom";
import HomeLayout from "../Layouts/HomeLayout";
import AboutUs from "../Pages/AboutUs";
import Team from "../Pages/TeamPages/Team";
import TeamDetails from "../Pages/TeamPages/TeamDetails";
import Contact from "../Pages/Contact";
import ErrorPage from "../Pages/ErrorPage";
import Home01 from "../Pages/HomePages/Home01";
//import Login from "../Pages/Login";
import ProjectDetails from "../Pages/ProjectPages/ProjectDetails";
import ProjectGallery from "../containers/ProjectContainers/ProjectGallery";


export const routes = createBrowserRouter([
    {
        path: "/",
        element: <HomeLayout />,
        children: [

            {
                path: '/projects',
                element: <ProjectGallery/>,
            },
            {
                path: '/project-details',
                element: <ProjectDetails />,
            },
            {
               path: '/team',
               element: <Team />,
            },
            {
                path: '/team-details',
                element: <TeamDetails />,
            },
            {
                path: '/contact',
                element: <Contact />,
            },

            {
                path: '/about',
                element: <AboutUs />,
            },
            {
                path: '/',
                element: <Home01/>,
            },

            {
                path: '*',
                element: <ErrorPage />,
            },

        ]
    }
])