import React, { useState, useEffect } from "react";
import { EffectFade, Navigation } from "swiper";
import { Pagination } from "swiper/core";
import { Swiper, SwiperSlide } from "swiper/react";
import SliderCard from "../../components/SliderElements/SliderCard";
//import { useProjectData } from '../../hooks/useProjectData';
import { useProjectData } from '../../data/ProjectDataContext';


export const Sliders = () => {
    //const SliderCardData = useProjectData();
    const { projectData, isLoading, error } = useProjectData();

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!projectData || projectData.length === 0) return <div>No project data available</div>;
    //console.log(projectData)
    return (
        <div className="theme_slider bg-black">
            <div className="container">
                <div className="swiper swiper_theme_slider" data-swiper-autoplay="2000">
                    <Swiper
                        modules={[Pagination, EffectFade, Navigation]}
                        effect="slide"
                        slidesPerView={1}
                        loop={true}
                        pagination={{
                            clickable: true,
                            dynamicBullets: true,
                        }}
                        navigation={{
                            prevEl: ".swiper-button-prev",
                            nextEl: ".swiper-button-next",
                        }}>
                        {projectData.map((data) => (
                            <SwiperSlide>
                                <SliderCard key={data.key} data={data}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>

                    {/* <!-- Add Buttons --> */}
                    <div className="swiper-navigation">
                        <div className="swiper-button-prev">
                            <i className="bi bi-arrow-left"></i>
                        </div>
                        <div className="swiper-button-next">
                            <i className="bi bi-arrow-right"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}
