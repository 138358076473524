import React from 'react';

const TeamDetailsContainer = (props) => {

    const { img,name,designation,blurb } = props.data_state;
    return (
        <main className="wrapper">
            {/* <!-- Our Team --> */}
            <section className="team team-details">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 col-md-7 pe-lg-5">
                            <div className="team-member-img">
                                <img src={img} alt="img" />
                            </div>
                        </div>

                        <div className="col-lg-5 col-md-12 ps-lg-0">
                            <div className="team-member-information">
                                <h5 className="name text-white">{name}</h5>
                                <h6 className="designation text-uppercase text-olive">{designation}</h6>
                                <h6 className="team-member-information">{blurb}</h6>


                            </div>
                        </div>
                    </div>


                </div>
            </section>
        </main>
    );
};

export default TeamDetailsContainer;