import axios from 'axios';

// Function to fetch project data from API Gateway
async function fetchProjectData() {
    try {
        console.log("Fetching data from API...");
        const response = await axios.get('https://zpenyvg1n1.execute-api.us-west-2.amazonaws.com/prd/projects/');
        console.log("Full API Response:", response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching project data:', error);
        return null;
    }
}

// Instead of exporting projectData directly, export a function that returns a promise
export async function getProjectData() {
    let cachedData = null;

    if (cachedData) {
        return cachedData;
    } else {
        const response = await fetchProjectData();
        console.log("Full response received:", response);

        if (!response || !response.body) {
            console.error('Failed to fetch project data or body is undefined');
            return [];
        }

        let rawData;
        try {
            rawData = JSON.parse(response.body);
        } catch (error) {
            console.error('Error parsing JSON from body:', error);
            return [];
        }

        console.log("Raw data extracted:", rawData);

        if (!Array.isArray(rawData)) {
            console.error('Unexpected data structure:', rawData);
            return [];
        }
        // Transform the data to match ProjectData structure
        cachedData = rawData.map(item => ({
            id: item.id,
            img: item.img,
            isFeature: item.isFeature,
            isInGallery: item.isInGallery,
            name: item.name,
            details:item.details,
            project_lead: item.project_lead,
            highlight: item.highlight,
            type: item.type,
            completion_year: item.completion_year,
            location: item.location,
            client_bio: item.client_bio,
            project_features_1: item.project_features_1,
            project_features_bullet_1: item.project_features_bullet_1,
            project_features_2: item.project_features_2,
            project_features_bullet_2: item.project_features_bullet_2,
            project_features_3: item.project_features_3,
            project_features_bullet_3: item.project_features_bullet_3,
            bgImg: item.bgImg,
            tag: item.tag || "workplace-design", // Use the tag from API or default to "workplace-design"
            // Transform detail_images

            detail_images: (() => {
                try {
                    const parsedImages = item.detail_images
                    return Array.isArray(parsedImages) ? parsedImages.map((detail_images) => ({
                        image: detail_images.image,
                        id: detail_images.id
                    })) : [];
                } catch (error) {
                    console.error(`Error parsing detailImages for item ${item.id}:`, error);
                    return [];
                }
            })()
        }));
        console.log(cachedData)

        return cachedData;
    }
}








