import React, { createContext, useState, useEffect, useContext, useMemo } from 'react';
import { getTeamData } from './team_data';

const TeamDataContext = createContext();

export const TeamDataProvider = ({ children }) => {
    const [teamData, setTeamData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            console.log('TeamDataProvider: Fetching data...');
            try {
                const data = await getTeamData();
                console.log('TeamDataProvider: Data fetched successfully', data);
                setTeamData(data);
                setIsLoading(false);
            } catch (err) {
                console.error('TeamDataProvider: Error fetching data', err);
                setError(err.message);
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    // Use useMemo to memoize the context value
    const value = useMemo(() => ({
        teamData,
        isLoading,
        error
    }), [teamData, isLoading, error]);

    console.log('TeamDataProvider rendering with:', value);

    useEffect(() => {
        if (teamData) {
            sessionStorage.setItem('teamData', JSON.stringify(teamData));
        }
    }, [teamData]);

    return (
        <TeamDataContext.Provider value={value}>
            {children}
        </TeamDataContext.Provider>
    );
};

export const useTeamData = () => {
    const context = useContext(TeamDataContext);
    if (context === undefined) {
        throw new Error('useTeamData must be used within a TeamDataProvider');
    }
    return context;
};